import React, { ReactNode } from 'react'
import { List, Text } from '../../styles'

export interface IFAQItem {
  title: string
  body: ReactNode
  show?: boolean
}

export const faqItems: IFAQItem[] = [
  {
    title: 'Can I try My School Files without paying for a subscription?',
    body:
      'Yes! All new users can try My School Files risk-free for 30 days. No credit card or subscription plan is required. Simply download the app to your mobile phone, create an account, and start adding to your portfolio.'
  },
  {
    title: 'What can I do during the free trial period?',
    body:
      "You will have 30 days to try My School Files and start building portfolios. Add students, school items, and calendar items. You can view your student's portfolio at any time from the student screen. In order to share the portfolio or download it as a PDF, you need to select a subscription plan."
  },
  {
    title:
      'How do I jump from the free trial to a paid subscription so I can share my portfolio?',
    body:
      'You can go to the Settings screen, tap on Subscription, then Select a Plan. Once a plan has been purchased, you will be able to share and download portfolios.'
  },
  {
    title: 'What happens when my free trial expires?',
    body:
      'You will still be able to view your portfolio, but you will no longer be able to add, edit, or delete anything until you select and purchase a subscription. Once you pay for a subscription, you will be able to continue building your portfolio and unlock the ability to securely share it and download it as a PDF.'
  },
  {
    title: 'What happens if I cancel my subscription?',
    body: (
      <>
        <Text>If a paid subscription is expired:</Text>
        <List>
          <li>All your data is preserved and accessible.</li>
          <li>
            You can still view the portfolio online and share it with others.
          </li>
          <li>
            You won’t be able to add anything new, edit existing items, or
            delete anything like school items, calendar items, etc.
          </li>
          <li>
            Resubscribing will unlock the ability to again add, edit, and
            delete.
          </li>
        </List>
      </>
    )
  },
  {
    title: 'How can I change or cancel my subscription?',
    body:
      'From the mobile app, go to the settings screen, tap on Subscription, and select "Manage Subscription"'
  },
  {
    title:
      "I purchased a subscription but when I tried to restore it in the app, it didn't find it.",
    body:
      'Make sure you are logged in to the App Store or Play Store with the same account you originally used to purchase a subscription. Once you have logged in to the store with the original account, go back to the app and try restoring again.'
  },
  {
    title: 'How can I request a refund?',
    body:
      'Subscriptions and payments are managed by Apple or Google depending on your device. You will need to contact them directly to request a refund.'
  },

  {
    title: 'When I logged in my data was missing. Where did it go?',
    body:
      'My School Files supports creating an account with a traditional username and password or by logging in with your existing Facebook, Apple, or Google account. These are unique and separate accounts even if they share the same email address. This is usually the reason you see different or missing data. Make sure you always log in the same way. If you need further assistance, please reach out to us directly.'
  },
  {
    title: 'When I try to upload photos, nothing happens.',
    body:
      'To upload images or use your camera to take pictures and upload them in the app, you need to allow the app access to do so. You can always go to the System settings of your device and make sure My School Files has access to all your photos and camera.'
  },

  {
    title: 'I am unable to set the correct date for a school item.',
    body:
      'When you select a date for a school item, the date range is restricted to the current school year you defined in Settings. If you want to add an item to another school year, you must change the school year first in Settings, then go back to add an item, and the date range to select from will be bound to the new school year.'
  },
  {
    title: 'How do I start a new school year in the app?',
    body:
      'Go into Settings, select Your current school year, and change the year from the menu.'
  },
  {
    title: 'How do I view past portfolios in a different school year',
    body:
      'Go into Settings, select Your current school year, and change the year from the menu.'
  },
  {
    title: 'How do I change school years in the app?',
    body:
      'Go into Settings, select Your current school year, and change the year from the menu.'
  }
]
