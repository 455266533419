import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import styled from 'styled-components'
import { H2, MediaQuery, Text } from '../styles'
import { faqItems, IFAQItem } from '../data/faq'
import { FaPlus } from 'react-icons/fa'
const Wrap = styled.div`
  padding: 50px 0 0 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* margin: 50px auto 0 auto; */
  max-width: 1200px;
`

const HeaderTitle = styled(H2)`
  font-size: 1.5rem;
  padding: 0 25px;
  text-transform: none;
  text-align: left;
  margin: 40px 0 0 0;
  @media ${MediaQuery.Medium} {
    font-size: 2.5rem;
  }
`

const ItemsWrap = styled.div`
  padding: 20px;
`

const QuestionWrap = styled.div`
  cursor: pointer;
  background: #fff;
  margin: 10px 0;
  padding: 20px;
`

const QuestionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Question = styled(Text)`
  font-weight: bold;
  font-size: 1rem;
  padding-right: 20px;
  @media ${MediaQuery.Medium} {
    font-size: 1.5rem;
  }
`

const BodyWrap = styled.div`
  padding: 10px 0 0 0;
  font-size: 1rem;
  p,
  li {
    font-size: 1rem;
  }
  li {
    padding: 5px 0;
  }
  ul {
    margin: 0 20px;
  }

  line-height: 1.5rem;
  @media ${MediaQuery.Medium} {
    font-size: 1.25rem;
    p,
    li {
      font-size: 1.25rem;
    }
    ul {
      margin: 0 40px;
    }
  }
`

interface FaqItemProps {
  item: IFAQItem
  showItem: () => void
}

const FaqItem: React.FC<FaqItemProps> = ({ item, showItem }) => {
  const { title, body, show } = item
  return (
    <QuestionWrap onClick={showItem}>
      <QuestionTitleContainer>
        <Question>{title}</Question>
        <FaPlus size={'1.25rem'} />
      </QuestionTitleContainer>
      {show && <BodyWrap>{body}</BodyWrap>}
    </QuestionWrap>
  )
}

const FAQPage = () => {
  const [items, setItems] = useState<IFAQItem[]>(faqItems)
  const showItem = (index: number) => {
    const newItems = [...items]
    newItems.forEach((x) => (x.show = false))

    setItems([
      ...newItems.slice(0, index),
      {
        ...newItems[index],
        show: true
      },
      ...newItems.slice(index + 1)
    ])
  }

  return (
    <Layout>
      <Seo title="FAQ" url="https://myschoolfiles.com/faq" />
      <div style={{}}>
        <Wrap>
          <HeaderTitle>Frequently Asked Questions</HeaderTitle>
          <ItemsWrap>
            {items.map((x, i) => {
              const showBody = () => {
                showItem(i)
              }
              return <FaqItem key={i} showItem={showBody} item={x} />
            })}
          </ItemsWrap>
        </Wrap>
      </div>
    </Layout>
  )
}

export default FAQPage
